.firstMessageLeft {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 80px;
    scroll-behavior: smooth;
    left: -400px;
    margin-left: 13px;
    display: grid;
    text-align: left;
    align-items: center;
    justify-content: start;
    padding: 12px;
    transition: opacity 0.5s ease, left 0.5s ease;
    /* Imposta una transizione per l'opacità e la trasformazione */
    z-index: 9999999999999;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, .3));

}

.containerMessages {
    transition: all 250ms;
}

.paragraphDiv p {
    margin: 0;
    width: auto;
}

.paragraphDiv {
    height: auto;
    position: relative;
    transition: all 1s ease;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: left;
    margin: 0px 0 18px 0;
    padding: 7px 10px;
    border-radius: 15px;
    transition: all 0.5s;
    /* Imposta una transizione per l'opacità e la trasformazione */
    z-index: 99999999999;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, .3));
    background-color: white;
}

.firstMessageRight,
.firstMessageLeft {
    width: auto;
    max-width: 400px;
    font-size: 14px;

}


.firstMessageLeft .paragraphDiv {
    width: fit-content;
    margin-right: auto;
}

.firstMessageRight .paragraphDiv {
    width: fit-content;
    margin-left: auto;
}

.firstMessageRight {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 80px;
    right: -400px;
    margin-right: 13px;
    /* display: grid ; */
    text-align: left;
    align-items: center;
    justify-content: end;
    padding: 12px;
    border-radius: 10px;
    transition: opacity 0.5s ease, right 0.5s ease;
    /* Imposta una transizione per l'opacità e la trasformazione */
    z-index: 99999999999;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, .3));


}


.paragraphDiv:last-child {
    margin-bottom: 20px;
}



.paragraphDiv:hover .buttonCloseFirstMessage {
    opacity: 1;
    transition: all 250ms;
}

.paragraphDiv:hover .buttonCloseFirstMessage {
    opacity: 1;
    transition: all 250ms;

}

.buttonChatbotRight {
    right: -400px;
    transition: opacity 0.5s ease, transform 0.5s ease, right 0.5s ease;
}


.buttonChatbotRight svg,
.buttonChatbotLeft svg {
    height: 70px;
    width: 70px;
    padding-right: 13px;
    padding-bottom: 15px;
}

svg#Livello_2 {
    -webkit-filter: drop-shadow(3px 0px 20px rgba(0, 0, 0, .3));
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, .3));
    /* Similar syntax to box-shadow */
}

.buttonChatbotRight {
    height: 80px;
    width: 80px;
    margin-right: 0;
    margin-bottom: 0;
}

.buttonChatbotLeft {
    height: 80px;
    width: 80px;
    margin-left: 13px;
    margin-bottom: 15px;
}

.buttonChatbotLeft {
    left: -400px;
    transition: opacity 0.5s ease, transform 0.5s ease, left 0.5s ease;
    transform: scaleX(-1);
}

button.buttonChatbotLeft:hover {
    transform: scaleX(-1) rotate(20deg) scale(1.1);
    /* Mantieni lo specchio e applica solo la rotazione */
}



.buttonChatbotRight:hover {
    transform: rotate(20deg) scale(1.1);
}




.buttonCloseFirstMessage::before {
    content: '✕';
}

.buttonCloseFirstMessage {
    position: absolute;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border: 0;
    opacity: 0;
    transition: all 250ms;
    padding: 0;
    font-size: 10pt;
    background-color: white;
    ;

}

.buttonCloseFirstMessage:hover {
    background-color: white;
}

.textFirstMessage {
    font-size: 14px;
}


.iframeContainerBefore {
    height: 0;
    width: 0;
    transition: all 250ms;
}

iframe {
    width: 100%;
    height: 100%;
    padding-bottom: 70px;
    border: 0px;
    overflow: hidden;
    display: block;
    opacity: 1;
}




@media screen and (min-width: 600px) {

    .iframeContainerSizes {
        /* max-height: 800px; */
        height: 920px;
        width: 400px;
    }
}



@media screen and (max-width: 599px) {
    .iframeContainerSizes {
        height: 90% !important;
        width: 90%;
    }
}